import { useTokenDistributorAddress } from "constants/addresses"

import { useContract } from "helpers/useContract.hook"

const abi = [
  //
  // roles
  "function CREATOR_ROLE() view returns (bytes32)",
  "function TEMPORARY_CREATOR_ROLE() view returns (bytes32)",
  "function hasRole(bytes32 role, address account) external view returns (bool)",
  //
  // events
  "event Withdraw(address indexed user, uint256 indexed distributionId, uint256 amount)",
  /// @dev Depositor does not have to be (and is usually not) a contributor
  "event Deposit(address indexed depositor, uint256 indexed distributionId, uint256 amount)",
  "event UndoDeposit(address indexed withdrawer, uint256 indexed distributionId, uint256 depositIndex, uint256 amount)",
  "event WithdrawIncorrectlySentFunds(address indexed tokenAddress, uint256 amount)",
  `event LogCreateDistribution(
      address indexed creator,
      uint256 indexed distributionId,
      string indexed name,
      address distributionToken
  )`,
  "event LogStartDistribution(address indexed creator, uint256 indexed distributionId)",
  "event LogAddContributors(address indexed user, uint256 indexed distributionId, uint256 contributorCount)",
  "event LogPauseDistribution(address indexed user, uint256 indexed distributionId, bool isPaused)",
  "event LogAbandonDistribution(address indexed user, uint256 indexed distributionId)",
  //
  // views
  // info of a specific distribution
  `function distributions(uint256 distributionId) view returns (
    string name,
    address distributionToken,
    uint256 totalRaised,
    uint256 totalDistributionTokenWithdrawn,
    bool paused,
    bool isSettingUp,
    address creator,
    bool abandoned
  )`,
  // total number of distributions
  "function distributionLength() external view returns (uint256)",
  // contributors for a specfiic distribution
  "function getContributors(uint256 _distributionId) external view returns (address[] memory)",
  // deposits for a specific distribution
  "function getDeposits(uint256 _distributionId) external view returns (tuple(uint256 amount, uint256 unlockTime)[])",
  // info for all distributions
  `function getDistributions() external view returns (
    tuple(
      string name,
      address distributionToken,
      uint256 totalRaised,
      uint256 totalDistributionTokenWithdrawn,
      bool paused,
      bool isSettingUp,
      address creator,
      bool abandoned
    )[]
  )`,
  // distribution ids for which _wallet is a contributor that have been started
  // but not abandoned
  `function getDistributionIdsForWallet(address _wallet) view returns (uint256[] memory)`,
  // information about a specific user
  "function userInfo(uint256 distributionId, address wallet) view returns (tuple(uint256 rewardWithdrawn, uint256 amountContributed) user)",
  // The total amount deposited for a distribution including future unlocks
  "function totalDeposited(uint256 _distributionId) public view returns (uint256)",
  // amount of tokens left to claim for a user
  "function pendingReward(uint256 _distributionId, address _user) view returns (int256)",
  //
  // mutations (admin)
  // create a distribution. This can usually only be called once by a temporary
  // creator. Contributors can be max 200 in length. More can be added with add
  // contributors
  "function create(string calldata name, address distributionToken, address[] calldata contributors, uint256[] calldata amounts) external",
  // add contributors in batches of 200
  "function addContributors(uint256 distributionId, address[] calldata contributors, uint256[] calldata amounts) public",
  // start a distribution, allowing users to claim tokens. Can only be called by
  // superadmin or the distribution's creator
  "function start(uint256 _distributionId) external",
  // deposit tokens into a vest (can be called by anyone)
  "function deposit(uint256 _distributionId, uint256 _amount, uint256 _unlockTime) external",
  // change whether distribution is paused. Paused distributions block
  // withdrawing for users. Only callable by EDITOR_ROLE
  "function setPaused(uint256 _distributionId, bool _paused) external",
  // Abandon a distribution. All deposits must be undone first. Can only be called by creator or
  // superadmin
  "function abandon(uint256 _distributionId) external",
  //
  // mutations (user)
  // claim tokens
  "function withdraw(uint256 _distributionId, uint256 _amount)",
]

export const useTokenDistributorContract = () => {
  const contractAddress = useTokenDistributorAddress()
  return useContract(contractAddress, abi)
}
