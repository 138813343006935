import { Button } from "@mantine/core"
import { Dots } from "components/loading/Dots.component"
import { BigNumber } from "ethers"
import { useApproval, useTokenAllowance } from "helpers/tokenQueries"
import { isAddress } from "helpers/useContract.hook"
import React from "react"

interface IApprovalButtonProps {
  tokenAddress: string
  contractAddress: string
  buttonReplaces: JSX.Element
  disabled?: boolean
  minAllowance: BigNumber
}

export const ApprovalButton: React.FC<IApprovalButtonProps> = ({
  tokenAddress,
  contractAddress,
  buttonReplaces,
  disabled,
  minAllowance,
}) => {
  const { data: tokenAllowance } = useTokenAllowance(tokenAddress, contractAddress)
  const { mutate: approve, isLoading: isApproving } = useApproval(tokenAddress, contractAddress)

  if (disabled) {
    return buttonReplaces
  }

  // required so UI looks decent whilst token address is being input
  if (!isAddress(tokenAddress)) {
    return buttonReplaces
  }

  // not sure if this is the best way to represent the allowance loading
  if (!tokenAllowance) {
    return <Button loading={true} />
  }

  if (tokenAllowance.gte(minAllowance)) {
    return buttonReplaces
  }

  return (
    <Button
      fullWidth
      onClick={() => approve()}
      disabled={tokenAddress.length === 0}
      loading={isApproving}
    >
      {isApproving ? <Dots>Approving</Dots> : "Approve"}
    </Button>
  )
}
