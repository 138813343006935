import { useWeb3React } from "@web3-react/core"
import { Contract } from "ethers"
import { useQueryClient, useMutation } from "react-query"

import { tokenDistributorKeys } from "./queryKeys"
import { useTokenDistributorContract } from "./useTokenDistributorContract.hook"

interface IClaimTokens {
  contract: Contract | null | undefined
  account: string | null | undefined
  distributionId: string
}

async function claimTokens({ contract, account, distributionId }: IClaimTokens) {
  if (!contract || !account) {
    throw new Error("Contract or account not given")
  }

  const pendingReward = await contract.pendingReward(distributionId, account)
  const tx = await contract.withdraw(distributionId, pendingReward)
  const receipt = await tx.wait()
  if (receipt.status !== 1) {
    throw new Error("Transaction failed")
  }
  return pendingReward
}

export const useClaimTokens = () => {
  const queryClient = useQueryClient()
  const { account } = useWeb3React()
  const contract = useTokenDistributorContract()

  return useMutation(
    (distributionId: string) => {
      return claimTokens({ contract, account, distributionId })
    },
    {
      onSuccess: () => {
        // return here to make sure it's awaited
        return queryClient.invalidateQueries(tokenDistributorKeys.list(account))
      },
    }
  )
}
