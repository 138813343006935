import { Zero } from "@ethersproject/constants"
import { parseUnits } from "@ethersproject/units"
import { BigNumber, BigNumberish, utils } from "ethers"

const formatBigNumber = (value: BigNumber, decimals: number, precision = 2): string =>
  Number(utils.formatUnits(value, decimals)).toFixed(precision)

BigNumber.prototype.mulDiv = function (multiplier: BigNumberish, divisor: BigNumberish): BigNumber {
  return BigNumber.from(divisor).gt(0) ? BigNumber.from(this).mul(multiplier).div(divisor) : Zero
}

// Returns a string which has a precision of decimals. Will truncate additional
// zeroes making this useful to format user input
// Use bn.toFixed(0) to parse as BUSD with no decimals
// ONLY WORKS WHEN THE TOKEN HAS 18 DECIMALS IN THE CONTRACT
BigNumber.prototype.toFixed = function (decimals: BigNumberish = 18): string {
  const amount = BigNumber.from(this)
  let precision = Number(decimals)

  let formatted = formatBigNumber(amount, 18, precision)

  while (precision >= 0) {
    const newFormatted = formatBigNumber(amount, 18, precision)
    if (Number(newFormatted) !== Number(formatted)) {
      return formatted
    }
    formatted = newFormatted
    precision--
  }
  return formatted
}

String.prototype.toBigNumber = function (decimals: BigNumberish): BigNumber {
  try {
    return parseUnits(this as string, decimals)
  } catch (error) {
    console.error(`Failed to parse input amount: "${this}"`, error)
  }
  return BigNumber.from(0)
}

// if (typeof window !== "undefined" && process.env.NODE_ENV === "development") {
//   const whyDidYouRender = require("@welldone-software/why-did-you-render")

//   // See https://github.com/welldone-software/why-did-you-render#options
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//     trackHooks: true,
//     logOwnerReasons: true,
//     collapseGroups: true,
//   })
// }
