import { useWeb3React } from "@web3-react/core"
import isPast from "date-fns/isPast"
import { dropWhile } from "ramda"
import { useQuery } from "react-query"
import invariant from "tiny-invariant"

import type { IUserTokenDistribution } from "../types"

import { useDeposits } from "./adminQueries"
import { tokenDistributorKeys } from "./queryKeys"
import { useTokenDistributorContract } from "./useTokenDistributorContract.hook"

// we get these separately because it's an expensive view call (iterates over
// all contributors in all contributions) and we think calling it many times may
// get us rate limited by a node
const useWalletDistributionIds = () => {
  const { account, chainId } = useWeb3React()
  const contract = useTokenDistributorContract()

  return useQuery<number[]>(
    // query key
    tokenDistributorKeys.walletDistributionIds(account, chainId),
    // return function
    async () => {
      invariant(contract, "Contract not given")
      invariant(account, "Account not given")

      const numDistributions = await contract.distributionLength()
      const allDistributionIds = []
      for (let i = 0; i < numDistributions; i++) {
        const contributors = await contract.getContributors(i)
        if (contributors.includes(account)) {
          allDistributionIds.push(i)
        }
      }

      const distributionIds = []
      for (const distributionId of allDistributionIds) {
        if (contract.version === 1) {
          distributionIds.push(distributionId)
          continue
        }

        const distribution = await contract.distributions(distributionId)
        const paused = distribution[4]
        const isSettingUp = distribution[5]
        const abandoned = distribution[7]
        if (!isSettingUp && !abandoned && !paused) {
          distributionIds.push(distributionId)
        }
      }

      return distributionIds
    },
    {
      enabled: Boolean(account) && Boolean(contract),
      // this rarely changes
      staleTime: 60_000,
    }
  )
}

export const useTokenDistributions = () => {
  const { account, chainId } = useWeb3React()
  const contract = useTokenDistributorContract()
  const { data: distributionIds } = useWalletDistributionIds()

  return useQuery<IUserTokenDistribution[], Error>(
    // query key
    tokenDistributorKeys.list(account, chainId),
    // return function
    async () => {
      invariant(distributionIds, "DistributionIds not given")
      invariant(contract, "Contract not given")

      const result: IUserTokenDistribution[] = []
      for (const distributionId of distributionIds) {
        const distributionInfo = await contract.distributions(distributionId)
        const userInfo = await contract.userInfo(distributionId, account)
        const pendingReward = await contract.pendingReward(distributionId, account)

        result.push({
          distributionId: distributionId.toString(),
          name: distributionInfo[0],
          tokenAddress: distributionInfo[1],
          tokensWithdrawn: userInfo[0],
          pendingTokens: pendingReward,
        })
      }

      return result
    },
    // query options
    {
      enabled: Boolean(account) && Boolean(contract) && Boolean(distributionIds),
    }
  )
}

export const useNextUnlockDate = (distributionId: string) => {
  const { data: deposits } = useDeposits(distributionId)

  if (!deposits) {
    return null
  }

  const depositDates = deposits.deposits.map(
    (deposit) => new Date(deposit.unlockTime.toNumber() * 1000)
  )
  const nextUnlockDate = dropWhile(isPast, depositDates)[0]

  return nextUnlockDate || null
}
