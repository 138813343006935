import { useTokenDistributorAddress } from "constants/addresses"

import {
  Table,
  Alert,
  Spoiler,
  Text,
  Title,
  Anchor,
  TextInput,
  Button,
  Checkbox,
  Card,
  Group,
  SimpleGrid,
} from "@mantine/core"
import { ApprovalButton } from "components/ApprovalButton.component"
import { DatePicker } from "components/DatePicker.component"
import { Box, Flex } from "components/Layout.component"
import { Spinner } from "components/loading/Spinner.component"
import isFuture from "date-fns/isFuture"
import setMinutes from "date-fns/setMinutes"
import setSeconds from "date-fns/setSeconds"
import { BigNumber, BigNumberish } from "ethers"
import { secondsToNow, secondsToHuman } from "helpers/dates"
import { formatNumber } from "helpers/numbers"
import React from "react"
import { useNavigate } from "react-router-dom"
import invariant from "tiny-invariant"

import {
  useMakeDeposit,
  useStartDistribution,
  useAbandonDistribution,
} from "../queries/adminMutations"
import { useContributorAddresses, useDeposits } from "../queries/adminQueries"
import type { IDistributionInfo } from "../queries/types"

interface IDepositButtonProps {
  distribution: IDistributionInfo
  amount: string
  timestampInSeconds: number
}

const DepositButton: React.FC<IDepositButtonProps> = ({
  distribution,
  amount,
  timestampInSeconds,
}) => {
  const { mutate: makeDeposit, isLoading: isMakeDepositLoading } = useMakeDeposit()
  const tokenDistributorAddress = useTokenDistributorAddress()
  return (
    <ApprovalButton
      minAllowance={amount.toBigNumber(18)}
      contractAddress={tokenDistributorAddress}
      tokenAddress={distribution.distributionToken}
      buttonReplaces={
        <Button
          fullWidth
          disabled={timestampInSeconds === 0 || amount.length === 0}
          loading={isMakeDepositLoading}
          onClick={() => {
            invariant(distribution, "distribution not given")
            invariant(amount.length, "amount not given")
            invariant(timestampInSeconds, "time must be in the future")
            makeDeposit({
              distributionId: distribution.distributionId,
              amountHuman: amount,
              unlockTime: timestampInSeconds,
            })
          }}
        >
          Deposit
        </Button>
      }
    />
  )
}

const Deposits: React.FC<{ distributionId: BigNumberish }> = ({ distributionId }) => {
  const { data: deposits, isLoading, isError } = useDeposits(distributionId)
  if (!deposits || isError) {
    return null
  }

  if (isLoading) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  return (
    <>
      <Box pl="7px">
        <Title order={3}>Deposits</Title>
      </Box>
      <Table striped>
        <caption>Total deposited: {deposits.totalDeposited.toFixed(6)}</caption>
        <thead>
          <tr>
            <th>Unlock time</th>
            <th>Amount</th>
            <th>Deposit id</th>
          </tr>
        </thead>
        <tbody>
          {deposits.deposits.map(({ amount, unlockTime, depositId }) => {
            const isInPast = !isFuture(new Date(unlockTime.toNumber() * 1000))
            return (
              <tr key={depositId.toString()}>
                <td>
                  {secondsToHuman(unlockTime.toNumber())}{" "}
                  <Text size="sm" color="gray" component="span">
                    {!isInPast && `(${secondsToNow(unlockTime.toNumber())})`}
                  </Text>
                </td>
                <td>{amount.toFixed(2)}</td>
                <td>{depositId}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
}

const ContributorInfo: React.FC<{ distributionId: BigNumberish }> = ({ distributionId }) => {
  const { data: contributorAddresses } = useContributorAddresses(distributionId)
  if (!contributorAddresses) {
    return null
  }

  return (
    <Spoiler
      maxHeight={0}
      showLabel={`Show all ${contributorAddresses.length} contributors`}
      hideLabel={`Hide ${contributorAddresses.length} contributors`}
    >
      <Card>
        <Text weight={500}>Contributors</Text>
        {contributorAddresses.map((address) => (
          <Text key={address}>{address}</Text>
        ))}
      </Card>
    </Spoiler>
  )
}

const AbandonedAlertBox = () => (
  <Box p={2}>
    <Alert title="Distribution has been abandoned" color="red">
      This distribution has been abandoned and cannot be edited
    </Alert>
  </Box>
)

const SetupAlertBox: React.FC<{ distribution: IDistributionInfo; onAbandon?: () => void }> = ({
  distribution,
  onAbandon,
}) => {
  const { mutate: startDistribution, isLoading: isStartLoading } = useStartDistribution(
    distribution.distributionId
  )
  const { mutate: abandonDistribution, isLoading: isAbandonLoading } = useAbandonDistribution(
    distribution.distributionId
  )
  const navigate = useNavigate()

  if (distribution.abandoned) {
    return <AbandonedAlertBox />
  }

  if (!distribution.isSettingUp) {
    return null
  }
  return (
    <Box p={2}>
      <Alert title="Distribution in setup mode" color="orange">
        <Flex flexDirection="column">
          <Text>This distribution is still in setup mode and is NOT being shown to users.</Text>
          <Text>
            Start the distribution to finalise setup. Tokens can be deposited at any time.
          </Text>
          <Text>If you made a mistake you can abandon your distribution and start again.</Text>
          <Flex mt={2} alignItems="center">
            <Button
              disabled={isStartLoading || isAbandonLoading}
              loading={isStartLoading}
              onClick={() =>
                startDistribution(undefined, {
                  onSuccess: () => navigate(`/admin/distribute/${distribution.distributionId}`),
                })
              }
            >
              Start distribution
            </Button>
            <Box ml={4}>
              <Button
                variant="outline"
                color="red"
                disabled={isStartLoading || isAbandonLoading}
                loading={isAbandonLoading}
                onClick={() =>
                  abandonDistribution(undefined, { onSuccess: () => onAbandon && onAbandon() })
                }
              >
                Abandon distribution
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Alert>
    </Box>
  )
}

export const Deposit: React.FC<{ distribution: IDistributionInfo; onAbandon?: () => void }> = ({
  distribution,
  onAbandon,
}) => {
  const [amount, setAmount] = React.useState<string>("")
  const [time, setTime] = React.useState(setSeconds(setMinutes(new Date(), 0), 0))
  const timestampInSeconds = Math.floor(Number(time) / 1000)
  const isInPast = !isFuture(new Date(timestampInSeconds * 1000))

  return (
    <Box>
      <SetupAlertBox distribution={distribution} onAbandon={onAbandon} />
      <Box mt={1}>
        <Card padding="xl" withBorder>
          <Card.Section>
            <Flex flexDirection="column" alignItems="center" width="100%" py={4}>
              <Title align="center" order={3}>
                {distribution.name}
              </Title>
              <Text>Distribution id: {distribution.distributionId.toString()}</Text>
              <Anchor
                target="_blank"
                rel="noreferrer"
                href={`https://bscscan.com/token/${distribution.distributionToken}`}
              >
                {distribution.distributionToken}
              </Anchor>
            </Flex>
          </Card.Section>

          <Text>Fundraise amount: ${formatNumber(distribution.totalRaised.toFixed(2))}</Text>
          <Text>
            Num tokens withdrawn: {distribution.totalDistributionTokenWithdrawn.toFixed(6)}
          </Text>
          <Text>Created by: {distribution.creator}</Text>

          <ContributorInfo distributionId={distribution.distributionId} />
        </Card>
      </Box>

      <Box mt={5}>
        <Deposits distributionId={distribution.distributionId} />
      </Box>

      <Flex alignItems="center" mt={3} justifyContent="flex-start">
        <Box maxWidth="50%">
          <Title order={5}>Amount</Title>
          <Box mt={1}>
            <TextInput value={amount} onChange={(e) => setAmount(e.currentTarget.value)} required />
          </Box>
        </Box>

        <Box maxWidth="50%" ml={3}>
          <Title order={5}>Unlock Date & Time</Title>
          <Box mt={1}>
            <DatePicker
              showTime={{ format: "HH:mm" }}
              onChange={(date: any) => setTime(date)}
              value={time}
            />
          </Box>
        </Box>

        <Box mt={4} ml={2}>
          {isInPast ? (
            <Text color="red">Date is in the past</Text>
          ) : (
            <Text variant="gradient" gradient={{ from: "indigo", to: "cyan", deg: 45 }}>
              {secondsToNow(timestampInSeconds)} from now
            </Text>
          )}
        </Box>
      </Flex>

      <Box mt={3}>
        <DepositButton
          distribution={distribution}
          amount={amount}
          timestampInSeconds={timestampInSeconds}
        />
      </Box>
    </Box>
  )
}
