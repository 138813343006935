import { ETH_MAINNET_ID, BSC_MAINNET_ID, isSupportedChain } from "constants/chainIds"

import { useWeb3React } from "@web3-react/core"
import invariant from "tiny-invariant"

// Addresses should NEVER be imported directly. They should always be reliant
// on the chainId.

const USDAddresses = {
  // BUSD
  [BSC_MAINNET_ID]: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  // USDT
  [ETH_MAINNET_ID]: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
}

const tokenDistributorAddresses = {
  [BSC_MAINNET_ID]: "0xb6Bd6bfC70723f385159F5bE9dEF1A7bFf4e175c",
  [ETH_MAINNET_ID]: "0x3B163d70C0da61c6E18ff988F8e4251de2A6b22a",
}

const shopkeeperAddresses = {
  [BSC_MAINNET_ID]: "0xF90d6A98fB2F21a68F35095bCCfb35173D53C836",
  [ETH_MAINNET_ID]: "0xeBc71Cc01Fb873C87F63719fC807f0252cb77291",
}

// Returns the USD stablecoin address. BUSD on BSC and USDT on ETH.
export const useUSDAddress = () => {
  const { chainId } = useWeb3React()
  invariant(isSupportedChain(chainId), `Chain id ${chainId} not supported`)

  const address = USDAddresses[chainId]
  invariant(address, `USD address on chain id ${chainId} not supported!`)

  return address
}

export const useTokenDistributorAddress = () => {
  const { chainId } = useWeb3React()
  invariant(isSupportedChain(chainId), `Chain id ${chainId} not supported`)

  const address = tokenDistributorAddresses[chainId]
  invariant(address, `Token distributor address on chain id ${chainId} not supported!`)

  return address
}

export const useShopkeeperAddress = () => {
  const { chainId } = useWeb3React()
  invariant(isSupportedChain(chainId), `Chain id ${chainId} not supported`)

  const address = shopkeeperAddresses[chainId]
  invariant(address, `Shopkeeper address on chain id ${chainId} not supported!`)

  return address
}
