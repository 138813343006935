import { Button, Text, Title } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { Table } from "antd"
import { Box } from "components/Layout.component"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { BigNumber } from "ethers"
import { formatNumber } from "helpers/numbers"
import React from "react"
import { theme } from "theme"

import { useClaimTokens } from "./queries/userMutations"
import { useNextUnlockDate, useTokenDistributions } from "./queries/userQueries"
import type { IUserTokenDistribution } from "./types"

const { Column } = Table

const ClaimButton: React.FC<{ distribution: IUserTokenDistribution }> = ({ distribution }) => {
  const { mutate: claim, isLoading: isClaimLoading } = useClaimTokens()
  const nextUnlockDate = useNextUnlockDate(distribution.distributionId.toString())

  const noTokensToClaim = distribution.pendingTokens.isZero()

  return (
    <Button
      fullWidth
      color={noTokensToClaim && nextUnlockDate ? "dark" : "default"}
      disabled={noTokensToClaim}
      loading={isClaimLoading}
      onClick={() => claim(distribution.distributionId)}
    >
      {noTokensToClaim && nextUnlockDate
        ? `Next unlock in ${formatDistanceToNowStrict(nextUnlockDate)}`
        : "Claim"}
    </Button>
  )
}

// when changing column names make sure you update the CSS styles for mobile
// above as we inject the row names via CSS
const TokenDistributorPage = () => {
  const { data: distributions, isLoading } = useTokenDistributions()
  const isDesktop = useMediaQuery(theme.mediaQueries.sm)

  return (
    <Box>
      <Box mb={{ _: 0, sm: 5 }}>
        <Title order={1}>Token Distributor</Title>
      </Box>
      <Box mt={{ _: 3, sm: 0 }}>
        <Table rowKey="distributionId" dataSource={distributions} loading={isLoading}>
          <Column
            title="Project Name"
            dataIndex="name"
            key="name"
            render={(value: string) => <Title order={6}>{value}</Title>}
          />
          <Column
            title="Token address"
            dataIndex="tokenAddress"
            key="tokenAddress"
            render={(value: string) => {
              const snipped = `${value.slice(0, 6)}...${value.slice(-4)}`
              return (
                <Text size="xs" color="gray">
                  {snipped}
                </Text>
              )
            }}
          />
          <Column
            title="Unclaimed Tokens"
            dataIndex="pendingTokens"
            key="pendingTokens"
            render={(value: BigNumber) => <Title order={6}>{formatNumber(value.toFixed(4))}</Title>}
          />
          <Column
            title="Claimed Tokens"
            dataIndex="tokensWithdrawn"
            key="tokensWithdrawn"
            render={(value: BigNumber) => <Title order={6}>{formatNumber(value.toFixed(4))}</Title>}
          />
          <Column
            width={isDesktop ? 250 : undefined}
            title="Claim"
            dataIndex=""
            key="claim"
            render={(value: any, row: IUserTokenDistribution, index: any) => {
              return <ClaimButton distribution={row} />
            }}
          />
        </Table>
      </Box>
    </Box>
  )
}

export default TokenDistributorPage
