export const ENTITIES = {
  LIST: "list",
  ITEM: "item",
}

export const PROVIDERS = {
  WEB3: "web3",
  BACKEND: "backend",
}

export const FEATURES = {
  ERC20: "erc20",
  DEAL_BACKEND: "dealBackend",
  DEAL_CONTRACT: "dealContract",
  SALE_BACKEND: "saleBackend",
  STAKED_SFEX: "stakedSfex",
  FARMING: "farming",
  NOTIFICATION: "notification",
  SHOWCASE: "showcase",
  SHOPKEEPER: "shopkeeper",
}
