import {
  Button,
  TextInput,
  Text,
  Title,
  Group,
  ActionIcon,
  NumberInput,
  NumberInputHandlers,
} from "@mantine/core"
import { useWeb3React } from "@web3-react/core"
import { DatePicker } from "components/DatePicker.component"
import { Box, Flex } from "components/Layout.component"
import { Dots } from "components/loading/Dots.component"
import { Spinner } from "components/loading/Spinner.component"
import setMinutes from "date-fns/setMinutes"
import setSeconds from "date-fns/setSeconds"
import { secondsToHuman } from "helpers/dates"
import { useApproval, useTokenAllowance } from "helpers/tokenQueries"
import { isAddress } from "helpers/useContract.hook"
import React from "react"
import invariant from "tiny-invariant"

import { useBuyCredits, useGrantCreationPerms, useClaimRefund } from "./mutations"

const BuyMoreCredits = () => {
  const [amount, setAmount] = React.useState<number>(1)
  const [address, setAddress] = React.useState<string>("")
  const handlers = React.useRef<NumberInputHandlers>(null)
  const { mutate: buy, isLoading } = useBuyCredits()

  const addressError = isAddress(address) ? "" : "Address is not valid"

  return (
    <Box>
      <Title order={3}>Buy Credits</Title>
      <Box mt={3}>
        <Group spacing={5}>
          <ActionIcon size={42} variant="default" onClick={() => handlers?.current?.decrement()}>
            -
          </ActionIcon>
          <NumberInput
            hideControls
            value={amount}
            onChange={(val) => val && setAmount(val)}
            handlersRef={handlers}
            min={1}
            styles={{ input: { width: 54, textAlign: "center" } }}
          />
          <ActionIcon size={42} variant="default" onClick={() => handlers?.current?.increment()}>
            +
          </ActionIcon>
        </Group>
      </Box>
      <Box mt={3}>
        <TextInput
          value={address}
          onChange={(e) => setAddress(e.currentTarget.value)}
          label="Grant to address (optional)"
          placeholder="0x0Ef82..."
          error={address && addressError}
        />
      </Box>
      <Box mt={3}>
        <Button
          size="lg"
          loading={isLoading}
          disabled={isLoading || amount <= 0 || (Boolean(address) && !isAddress(address))}
          uppercase
          onClick={() => {
            buy(
              { amount, inputAddress: address ? address : undefined },
              {
                onSuccess: () => {
                  setAmount(1)
                  setAddress("")
                },
              }
            )
          }}
        >
          Buy
        </Button>
      </Box>
    </Box>
  )
}

const GrantAccess = () => {
  const [address, setAddress] = React.useState<string>("")
  const { mutate: grant } = useGrantCreationPerms()
  const addressError = isAddress(address) ? "" : "Address is not valid"

  return (
    <Box>
      <Title order={3}>Grant access</Title>
      <Box mt={3}>
        <TextInput
          value={address}
          onChange={(e) => setAddress(e.currentTarget.value)}
          label="Grant to creation permissions to address"
          placeholder="0x0Ef82..."
          error={address && addressError}
        />
      </Box>
      <Box mt={3}>
        <Button
          size="lg"
          disabled={!isAddress(address)}
          uppercase
          onClick={() => {
            grant(address, {
              onSuccess: () => {
                setAddress("")
              },
            })
          }}
        >
          Grant
        </Button>
      </Box>
    </Box>
  )
}

const Refund = () => {
  const [address, setAddress] = React.useState<string>("")
  const { mutate: refund } = useClaimRefund()
  const addressError = isAddress(address) ? "" : "Address is not valid"

  return (
    <Box>
      <Title order={3}>Refund</Title>
      <Box mt={3}>
        <TextInput
          value={address}
          onChange={(e) => setAddress(e.currentTarget.value)}
          label="Revoke creation permission and refund credit"
          placeholder="0x0Ef82..."
          error={address && addressError}
        />
      </Box>
      <Box mt={3}>
        <Button
          size="lg"
          disabled={!isAddress(address)}
          uppercase
          onClick={() => {
            refund(address, {
              onSuccess: () => {
                setAddress("")
              },
            })
          }}
        >
          Revoke & refund
        </Button>
      </Box>
    </Box>
  )
}

const ShopkeeperPage = () => {
  return (
    <Box>
      <Title order={1}>Shopkeeper</Title>
      <Box mt={3}>
        <BuyMoreCredits />
      </Box>
      <Box mt={3}>
        <GrantAccess />
      </Box>
      <Box mt={3}>
        <Refund />
      </Box>
    </Box>
  )
}

export default ShopkeeperPage
