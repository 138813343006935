import { useUSDAddress } from "constants/addresses"

import { Textarea, TextInput, Button, Text, Title } from "@mantine/core"
import { ApprovalButton } from "components/ApprovalButton.component"
import { Box, Flex } from "components/Layout.component"
import { BigNumber, ethers } from "ethers"
import { useHasBuyerRole } from "features/shopkeeper/queries"
import { useShopkeeperContractAddress } from "features/shopkeeper/useShopkeeper.hook"
import { formatNumber } from "helpers/numbers"
import { useTokenDecimals } from "helpers/tokenQueries"
import { splitEvery } from "ramda"
import React from "react"

import { parseContributors, IParseContributorResult } from "../parseContributors"
import { useAddContributors, useCreateDistribution } from "../queries/adminMutations"

interface ICreateTokenDistributionProps {
  onComplete?: () => void
}

export const CreateTokenDistribution: React.FC<ICreateTokenDistributionProps> = ({
  onComplete,
}) => {
  const [pastedContributors, setPastedContributors] = React.useState<string>("")
  const [contributorError, setContributorError] = React.useState<string>("")
  const [tokenAddressError, setTokenAddressError] = React.useState<string>("")
  const [result, setResult] = React.useState<IParseContributorResult>()
  const [name, setName] = React.useState<string>("")
  const [tokenAddress, setTokenAddress] = React.useState<string>("")
  const [isComplete, setIsComplete] = React.useState<boolean>(false)
  const shopkeeperAddress = useShopkeeperContractAddress()
  const usdAddress = useUSDAddress()

  const { mutateAsync: createDistribution, isLoading: createDistributionLoading } =
    useCreateDistribution()
  const { mutateAsync: addContributors, isLoading: addContributorsLoading } = useAddContributors()
  const [distributionId, setDistributionId] = React.useState<BigNumber | null>(null)
  const [addedContributorAddresses, setAddedContributorAddresses] = React.useState<Set<string>>(
    new Set()
  )
  const { data: numTokenDecimals } = useTokenDecimals(tokenAddress)
  const { data: hasBuyerRole } = useHasBuyerRole()

  React.useEffect(() => {
    try {
      const parsed = parseContributors(pastedContributors)
      setResult(parsed)
      setContributorError("")
    } catch (e: any) {
      setContributorError(e.message)
    }
  }, [pastedContributors])

  React.useEffect(() => {
    if (!tokenAddress) {
      return
    }
    try {
      ethers.utils.getAddress(tokenAddress)
      setTokenAddressError("")
    } catch (e: any) {
      setTokenAddressError(e.message)
    }
  }, [tokenAddress])

  const allAddressesAdded = result?.contributors.every((contributor) =>
    addedContributorAddresses.has(contributor.address)
  )

  const allDataProvided =
    tokenAddress &&
    name &&
    !tokenAddressError &&
    !contributorError &&
    result &&
    result.contributors.length > 0 &&
    !allAddressesAdded &&
    numTokenDecimals &&
    numTokenDecimals === 18

  const onCreateDistribution = async () => {
    if (!allDataProvided) {
      console.error("missing information required to create distribution")
      return
    }

    const [createBatch, ...addBatches] = splitEvery(200, result.contributors)

    // happy path if no errors in creation/adding of contributors
    if (distributionId === null) {
      const distributionId = await createDistribution({
        distributionName: name,
        distributionTokenAddress: ethers.utils.getAddress(tokenAddress),
        contributors: createBatch,
      })
      setDistributionId(distributionId)
      setAddedContributorAddresses(new Set(createBatch.map((c) => c.address)))

      for (const batch of addBatches) {
        await addContributors({
          distributionId,
          contributors: batch,
        })
        setAddedContributorAddresses((prev) => new Set([...prev, ...batch.map((c) => c.address)]))
      }
      setIsComplete(true)
      onComplete && onComplete()
      return
    }

    // if distribution was created in first tx but one or more of add
    // contributor txes failed
    const remainingContributors = result.contributors.filter(
      (c) => !addedContributorAddresses.has(c.address)
    )
    const batches = splitEvery(200, remainingContributors)
    for (const batch of batches) {
      await addContributors({
        distributionId,
        contributors: batch,
      })
      setAddedContributorAddresses((prev) => new Set([...prev, ...batch.map((c) => c.address)]))
    }
  }

  return (
    <Box>
      <Box>
        <Title order={3}>Paste contributors</Title>
        <Box mt={1}>
          <Flex>
            <Box width="50%">
              <Text>Enter one address and the amount they contributed on each line.</Text>
              <Box mt={1}>
                <Textarea
                  disabled={Boolean(distributionId)}
                  error={contributorError}
                  placeholder={`0x314ab97b76e39d63c78d5c86c2daf8eaa306b182 3.141592
0x271bffabd0f79b8bd4d7a1c245b7ec5b576ea98a,2.7182
0x141ca95b6177615fb1417cf70e930e102bf8f584=1.41421`}
                  autosize
                  value={pastedContributors}
                  onChange={(e) => setPastedContributors(e.currentTarget.value)}
                  minRows={11}
                />
              </Box>
            </Box>
            <Flex flexDirection="column" ml={3} width="50%">
              {result && (
                <Flex>
                  <Box mr={2}>
                    <Text>Total Contributions:</Text>
                    <Text>{formatNumber(result?.totalContributions)}</Text>
                  </Box>
                  <Box mr={2}>
                    <Text>Total Distinct Contributors:</Text>
                    <Text>{result?.totalDistinctContributors}</Text>
                  </Box>
                  <Box mr={2}>
                    <Text>Total Raised:</Text>
                    <Text>${result?.totalRaised.toFixed(4)}</Text>
                  </Box>
                </Flex>
              )}
              <Flex flexDirection="column" mt={4}>
                <Title order={5}>Distribution name</Title>
                <Box mt={1}>
                  <TextInput
                    disabled={Boolean(distributionId)}
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                  />
                </Box>
                <Box mt={2}>
                  <Title order={5}>Distribution Token Address</Title>
                </Box>
                <Box mt={1}>
                  <TextInput
                    disabled={Boolean(distributionId)}
                    value={tokenAddress}
                    error={tokenAddressError}
                    onChange={(e) => setTokenAddress(e.currentTarget.value)}
                  />
                  {numTokenDecimals && (
                    <Box mt={1}>
                      <Text>
                        Number of token decimals (MUST be equal to 18): {numTokenDecimals}
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box mt={4} width="50%">
                  <ApprovalButton
                    minAllowance={"1000".toBigNumber(18)}
                    contractAddress={shopkeeperAddress}
                    tokenAddress={usdAddress}
                    disabled={isComplete || !hasBuyerRole}
                    buttonReplaces={
                      <Button
                        loading={createDistributionLoading || addContributorsLoading}
                        disabled={!allDataProvided}
                        fullWidth
                        onClick={onCreateDistribution}
                      >
                        Create
                      </Button>
                    }
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}
