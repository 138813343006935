import { AppShell, Header as MantineHeader } from "@mantine/core"
import { Box } from "components/Layout.component"
import React from "react"
import { Outlet } from "react-router-dom"

import { ChainIdGuard } from "./components/ChainIdGuard.component"
import { Header } from "./components/Header.component"
import { SiteContainer } from "./components/SiteContainer.component"
import { useScrollOnRouteChange } from "./useScrollOnRouteChange.hook"

export const SiteLayout: React.FC = () => {
  useScrollOnRouteChange()
  return (
    <AppShell
      padding="md"
      header={
        <MantineHeader height={80} padding="md">
          <Box px={6}>
            <Header />
          </Box>
        </MantineHeader>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      })}
    >
      <SiteContainer>
        <Box maxWidth="1280px" mx="auto" py={{ _: 8, md: 10 }} px={{ _: 2, md: 5 }} width="100%">
          <ChainIdGuard>
            <Outlet />
          </ChainIdGuard>
        </Box>
      </SiteContainer>
    </AppShell>
  )
}
