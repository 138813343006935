import "./bootstrap"
import "./App.scss"

import "./reset.scss"
import Shopkeeper from "features/shopkeeper/Shopkeeper.page"
import { AdminLayout } from "features/site/Admin.layout"
import { SiteLayout } from "features/site/Site.layout"
import CreateStepper from "features/tokendistributor/CreateStepper.page"
import DistributeTokens from "features/tokendistributor/Distribute.page"
import SingleDistribution from "features/tokendistributor/SingleDistribution.page"
import TokenDistributor from "features/tokendistributor/TokenDistributor.page"
import useEagerConnect from "features/web3/useEagerConnect.hook"
import React from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { PAGE_URLS } from "routes"

function App() {
  useEagerConnect()

  return (
    <BrowserRouter>
      <Routes>
        {/* containts the header and footer of the site */}
        <Route path={PAGE_URLS.TOKEN_DISTRIBUTOR} element={<SiteLayout />}>
          <Route index element={<TokenDistributor />} />
        </Route>

        <Route path={PAGE_URLS.ADMIN} element={<AdminLayout />}>
          <Route path="create" element={<CreateStepper />} />

          <Route path="distribute" element={<DistributeTokens />} />
          <Route path="distribute/:distributionId" element={<SingleDistribution />} />
          <Route path="buy" element={<Shopkeeper />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default React.memo(App)
